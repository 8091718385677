<script>
import {addNewHiringCriteria, getAllHireRouteOfTSp, getAllVehicleType,} from "@/services/api/APIService";
import BackButton from "@/components/reuseables/BackButton.vue";
import Icons from "@/components/reuseables/Icons.vue";
import NewBaseButton from "@/components/reuseables/NewBaseButton.vue";
import {mapGetters} from "vuex";
import Modal from "@/components/reuseables/Modal.vue";

export default {
    name: 'CreateHiringCriteria',
    components: {
      Modal,
      NewBaseButton,
      Icons,
      BackButton
    },
    data() {
      return {
        loading: false,
        routes: [],
        vehicleTypeAndCapacities: [],
        hireData: {
          discountValue: "",
          numberOfDiscountedDays: "",
          hirePrice: '',
          discount: false,
          selectedRoutes: "",
          selectedVehicleType: {
            vehicleType: "",
            vehicleCapacity: ""
          },
        },

        showAddModal: false,
        companyData: {},
        refresh: true
      }
    },

    computed: {
      ...mapGetters("leasingComponent", ["hiringCriteriaData"]),
    },

    methods: {
      routeToDashboard(){
        this.$router.push({name: 'LeasingDashboard'})
        window.location.reload()
      },
      handleShowAddRouteModal(){
        this.showAddModal = !this.showAddModal;
        if(!this.showAddModal){
          this.getAllRoute()
        }
      },
      async getAllRoute() {
        let data = {}
        data.transportCompanyId = this.companyData.id
        await getAllHireRouteOfTSp(data)
          .then((res) => {
            this.routes = res.data
            this.routes.sort(function (a, b) {
              return (a.departure.city.localeCompare(b.destination.city))
            })
            this.routes.sort(function (a, b) {
              return (a.departureTerminalName.localeCompare(b.departureTerminalName))
            })
          })
          .catch((err) => {
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            })
          });
      },

      async getVehicleType() {
        let data = {}
        data.transportCompanyId = this.companyData.id;
        await getAllVehicleType(data)
          .then((res) => {
            res.data.forEach(vehicle => {
              let vehicleObject = {};
              vehicleObject.vehicleType = vehicle.vehicleType;
              vehicleObject.vehicleCapacity = vehicle.vehicleCapacity;
              vehicleObject.vehicle = vehicle;
              vehicleObject.fullDetail = vehicle.vehicleType + " - " + vehicle.vehicleCapacity;
              this.vehicleTypeAndCapacities.push(vehicleObject);

            })
          })
          .catch((err) => {
            console.log(err.response)
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            })
          })
      },

      getRouteText(item) {
        return this.toSentenceCase(item.departure.city) + " " +
          this.toSentenceCase(item.departure.state) + " " +
          this.toSentenceCase(item.departure.country) + " - " +
          this.toSentenceCase(item.destination.city) + " " +
          this.toSentenceCase(item.destination.state) + " " +
          this.toSentenceCase(item.destination.country)
      },

      toSentenceCase(text) {
        if (text) {
          text = text.toLowerCase()
          return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
        }
      },

      async addNewCriteria() {
        this.loading = true
        if (!this.hireData.selectedRoutes || !this.hireData.selectedVehicleType.vehicleType || !this.hireData.hirePrice) {
          this.$displaySnackbar({
            message: "Please fill in all required fields",
            success: false,
          });
          this.loading = false;
          return;
        }
        let data = {}
        data.transportCompanyId = this.companyData.id;
        data.routeId = this.hireData.selectedRoutes;
        data.vehicle = this.hireData.selectedVehicleType;
        data.hirePrice = this.hireData.hirePrice;
        data.discount = this.hireData.discount;
        data.discountValue = this.hireData.discountValue;
        data.numberOfDiscountedDays = this.hireData.numberOfDiscountedDays;

        await addNewHiringCriteria(data)
          .then(() => {
            this.$displaySnackbar({
              message: "Criteria created successfully",
              success: true,
            })
            this.loading = false
            this.refresh = false

            this.$router.push({
              name: 'AllHiringCriteria'
            }).then(()=>window.location.reload())

          }).catch(() => {
            this.loading = false
            this.$displaySnackbar({
              message: "Error creating criteria",
              success: false,
            });
        }).finally(() => {this.loading = false;});
      }
    },
    mounted() {
      this.refresh = false

        this.refresh = true

    },

    async created() {
      this.companyData = JSON.parse(sessionStorage.getItem("companyData"));
      await this.getVehicleType();
      await this.getAllRoute();
    }


  }
</script>

<template>
  <div class="tw-w-full" v-if="refresh">
      <back-button title="Leasing Overview" :color="companyData.companyColor" @back="$router.push({name:'LeasingDashboard'})" />

    <div class="display-center tw-w-full">
      <div class=" main-body tw-mt-20">
        <p class="head-txt" :style="{color : companyData.companyColor}">Create Hiring Criteria</p>
        <div class="tw-flex  tw-w-full tw-justify-between tw-mb-4">
          <validation-provider name="routes" rules="required" v-slot="{ classes, errors }" class="route ">
            <label class="header">Route</label>
            <v-select class="mt-4 block input-field" :items="routes" hide-details placeholder="Lagos-Benin"
              v-model="hireData.selectedRoutes" :item-text="getRouteText"  item-value="hireRouteId" solo>
              <template #append>
                <icons name="arrow-down" />
              </template>
            </v-select>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>

          <div class="add-route tw-flex tw-items-center tw-justify-center tw-pt-10" @click="handleShowAddRouteModal">
            <icons name="plus-icon" :color="companyData.companyColor" class="tw-cursor-pointer" />
          </div>


        </div>

        <div class="mb-4">
          <validation-provider name="vehicle type" rules="required" v-slot="{ classes, errors }">
            <label class="header">Vehicle Type</label>
            <v-select class="mt-4 input-field" :items="vehicleTypeAndCapacities" v-model="hireData.selectedVehicleType"
              hide-details placeholder="Vehicle type" solo item-text="fullDetail" item-value="vehicle">
              <template #append>
                <icons name="arrow-down" />
              </template>
            </v-select>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>
        </div>

        <label class="header">Price/day</label>
        <div class="tw-mt-6">
          <validation-provider name="price" rules="required" v-slot="{ classes, errors }">
            <v-text-field class="mt-4" v-model="hireData.hirePrice" hide-details item-text="name" placeholder="" solo>
              <template v-slot:prepend-inner>
                <icons name="naira-sign" :color="companyData.companyColor" />
              </template>
            </v-text-field>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>
        </div>


        <div class="tw-pt-0 tw-mt-10">
          <validation-provider rules="required" v-slot="{classes, errors}">
            <v-checkbox hide-details :color="companyData.companyColor" v-model="hireData.discount">
              <template #label>
                <label class="header tw-mt-1">
                  Discount
                </label>
              </template>
            </v-checkbox>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>
        </div>

        <div class="tw-flex tw-mt-5" v-if="hireData.discount">
          <validation-provider name="discount" rules="required" v-slot="{ classes, errors }">
            <label >No of Days</label>
            <v-text-field class="mt-4 mr-3" hide-details item-text="name" placeholder="6" solo 
              v-model="hireData.discountValue">
            </v-text-field>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>

          <validation-provider name="duration" rules="required" v-slot="{ classes, errors }">
            <label >Discount
              Value</label>
            <v-text-field class="mt-4" hide-details item-text="name" placeholder="10%" solo 
              v-model="hireData.numberOfDiscountedDays">
            </v-text-field>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>
        </div>





        <div class="tw-mt-10 btn-container">
          <div>
            <new-base-button :buttonText="'Cancel'" class="btn"
                             :isOutlined="true" outlined="outlined" @click="routeToDashboard" :color="companyData.companyColor"/>
          </div>
          <div>
            <new-base-button :buttonText="'Finish'" class="tw-ml-5 btn"
                             @click="addNewCriteria" :color="companyData.companyColor" :loading="loading"  />
          </div>
        </div>
      </div>
    </div>
    <modal section="add-hiring-route" :dialog="showAddModal" @close="handleShowAddRouteModal"  :color="companyData.companyColor" />
  </div>

</template>

<style scoped lang="scss">
  .main-body {
    height: fit-content;
    border-radius: 10px;
    background: #FDFFFC;
    padding: 55px;
    width: 900px;

    @media screen and (max-width: 768px) {
      margin-left: 15px;
      min-width: 700px;
    }

    @media screen and (max-width: 375px) {
      min-width: 100%;
      padding: 20px
    }
  }
  .route{
    width: 95%;
  }
  .add-route{
    width: 5%;
  }

  .head-txt {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
    color: #004AAD;
  }

  .header {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #4F4F4F;
  }

  .custom-checkbox .v-label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #4F4F4F;
  }

  .header-disabled {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #C4C4C4;
  }

  span.error-text {
    color: #EB5757;
    font-size: 12px;
    margin-top: 5px;
    font-family: inter;
    font-weight: 500;
  }

  .btn {
    @media screen and (max-width: 375px) {
      margin-top: 10px;
    }
  }

  .btn-container {
    display: flex;
    margin-left: 53%;
    @media screen and (max-width: 768px){
      margin-left: 40%;
    }
    @media screen and (max-width: 375px) {
      margin-left: -30px;
    }
  }
  .display-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>