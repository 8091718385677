var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.refresh)?_c('div',{staticClass:"tw-w-full"},[_c('back-button',{attrs:{"title":"Leasing Overview","color":_vm.companyData.companyColor},on:{"back":function($event){return _vm.$router.push({name:'LeasingDashboard'})}}}),_c('div',{staticClass:"display-center tw-w-full"},[_c('div',{staticClass:" main-body tw-mt-20"},[_c('p',{staticClass:"head-txt",style:({color : _vm.companyData.companyColor})},[_vm._v("Create Hiring Criteria")]),_c('div',{staticClass:"tw-flex  tw-w-full tw-justify-between tw-mb-4"},[_c('validation-provider',{staticClass:"route ",attrs:{"name":"routes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"header"},[_vm._v("Route")]),_c('v-select',{staticClass:"mt-4 block input-field",attrs:{"items":_vm.routes,"hide-details":"","placeholder":"Lagos-Benin","item-text":_vm.getRouteText,"item-value":"hireRouteId","solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icons',{attrs:{"name":"arrow-down"}})]},proxy:true}],null,true),model:{value:(_vm.hireData.selectedRoutes),callback:function ($$v) {_vm.$set(_vm.hireData, "selectedRoutes", $$v)},expression:"hireData.selectedRoutes"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,50564349)}),_c('div',{staticClass:"add-route tw-flex tw-items-center tw-justify-center tw-pt-10",on:{"click":_vm.handleShowAddRouteModal}},[_c('icons',{staticClass:"tw-cursor-pointer",attrs:{"name":"plus-icon","color":_vm.companyData.companyColor}})],1)],1),_c('div',{staticClass:"mb-4"},[_c('validation-provider',{attrs:{"name":"vehicle type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"header"},[_vm._v("Vehicle Type")]),_c('v-select',{staticClass:"mt-4 input-field",attrs:{"items":_vm.vehicleTypeAndCapacities,"hide-details":"","placeholder":"Vehicle type","solo":"","item-text":"fullDetail","item-value":"vehicle"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icons',{attrs:{"name":"arrow-down"}})]},proxy:true}],null,true),model:{value:(_vm.hireData.selectedVehicleType),callback:function ($$v) {_vm.$set(_vm.hireData, "selectedVehicleType", $$v)},expression:"hireData.selectedVehicleType"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,467000235)})],1),_c('label',{staticClass:"header"},[_vm._v("Price/day")]),_c('div',{staticClass:"tw-mt-6"},[_c('validation-provider',{attrs:{"name":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-4",attrs:{"hide-details":"","item-text":"name","placeholder":"","solo":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('icons',{attrs:{"name":"naira-sign","color":_vm.companyData.companyColor}})]},proxy:true}],null,true),model:{value:(_vm.hireData.hirePrice),callback:function ($$v) {_vm.$set(_vm.hireData, "hirePrice", $$v)},expression:"hireData.hirePrice"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3716457703)})],1),_c('div',{staticClass:"tw-pt-0 tw-mt-10"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"hide-details":"","color":_vm.companyData.companyColor},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"header tw-mt-1"},[_vm._v(" Discount ")])]},proxy:true}],null,true),model:{value:(_vm.hireData.discount),callback:function ($$v) {_vm.$set(_vm.hireData, "discount", $$v)},expression:"hireData.discount"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4209748214)})],1),(_vm.hireData.discount)?_c('div',{staticClass:"tw-flex tw-mt-5"},[_c('validation-provider',{attrs:{"name":"discount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',[_vm._v("No of Days")]),_c('v-text-field',{staticClass:"mt-4 mr-3",attrs:{"hide-details":"","item-text":"name","placeholder":"6","solo":""},model:{value:(_vm.hireData.discountValue),callback:function ($$v) {_vm.$set(_vm.hireData, "discountValue", $$v)},expression:"hireData.discountValue"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3386698582)}),_c('validation-provider',{attrs:{"name":"duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',[_vm._v("Discount Value")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"hide-details":"","item-text":"name","placeholder":"10%","solo":""},model:{value:(_vm.hireData.numberOfDiscountedDays),callback:function ($$v) {_vm.$set(_vm.hireData, "numberOfDiscountedDays", $$v)},expression:"hireData.numberOfDiscountedDays"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2031052155)})],1):_vm._e(),_c('div',{staticClass:"tw-mt-10 btn-container"},[_c('div',[_c('new-base-button',{staticClass:"btn",attrs:{"buttonText":'Cancel',"isOutlined":true,"outlined":"outlined","color":_vm.companyData.companyColor},on:{"click":_vm.routeToDashboard}})],1),_c('div',[_c('new-base-button',{staticClass:"tw-ml-5 btn",attrs:{"buttonText":'Finish',"color":_vm.companyData.companyColor,"loading":_vm.loading},on:{"click":_vm.addNewCriteria}})],1)])])]),_c('modal',{attrs:{"section":"add-hiring-route","dialog":_vm.showAddModal,"color":_vm.companyData.companyColor},on:{"close":_vm.handleShowAddRouteModal}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }